.app{
    margin: auto;
    margin-top: 10px;
    width: 60%;
    border: 3px solid #04AA6D;
    padding: 10px;
    border-radius: 10px;
}
.head{
    background-color: aliceblue;
    color: #04AA6D;
    display: flex;
    justify-content: center;
}
#table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #table td, #table th {
    border: 1px solid #ddd;
    padding: 8px;
    width: 50%;
  }
  
  #table tr:nth-child(even){background-color: #f2f2f2;}
  
  #table tr:hover {background-color: #ddd;}
  
  #table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #04AA6D;
    color: white;
  }
  #btn{
    width: 120px;
    height: 30px;
    background-color: rgb(239, 239, 249);
    color: rgb(9, 1, 1);
    font-size: 18px;
    border-radius: 5px;
    margin-top: 10px;
    margin-left:350px;
  }
  #btn:hover {background-color:#04AA6D;}